footer {
  background: #231f20;
  color: white;
  padding-top: 20px;
  padding-bottom: 15px;
  font-size: 12px; }
  @media screen and (max-width: 991px) {
    footer {
      text-align: center; } }
  footer .logo {
    height: 20px; }
  footer ul {
    display: flex; }
    footer ul li {
      padding: 5px; }
      footer ul li a {
        color: white; }
        footer ul li a:hover, footer ul li a:focus {
          text-decoration: underline;
          color: white; }
